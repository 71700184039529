import React from 'react';
import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import ReactHelmet from 'react-helmet';

const AppStorePage = () => (
  <BaseLayoutTemplate>
    <ReactHelmet htmlAttributes={{ lang: 'ja' }}>
      <meta
        http-equiv='refresh'
        content='0;URL=https://apps.apple.com/jp/app/croccha/id1420803859?ls=1'
      />
      <meta name='robots' content='noindex' />
    </ReactHelmet>
    <span>app storeに遷移しています...</span>
  </BaseLayoutTemplate>
);

export default AppStorePage;
